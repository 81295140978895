import React from 'react';

import Layout from '../../../components/layout/Layout';

const TechTemplate = () => {
    return (
        <Layout>
            <h2>
                Ripples is not just an organisation for good, we are also a
                FinTech
                <br />
                We want to use the best technology availble to us to provide the
                best solution for our customers
            </h2>
            <p>
                Read up on what we're doing to stay on the bleeding edge and our
                ups and downs through the process
            </p>
        </Layout>
    );
};

export default TechTemplate;
